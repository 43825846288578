import React, { useState } from 'react'
import { Col, Container, Modal, Navbar, Row, Spinner } from 'react-bootstrap'
import Sidebar from '../comman_page/Sidebar'
import { HeaderDashboard } from '../comman_page/HeaderDashboard'
import { Footer } from '../../../../public/containers/footer/Footer'
import { WebForm } from './WebForm'
import { useNavigate } from 'react-router-dom'
import AddClassImage from './AddClassImage'
import './Businessweb.css';
import AddWorkshopImage from './AddWorkshopImage'
import AddServiceImage from './AddServiceImage'
import CryptoJS from 'crypto-js';
import { book_appointment, class_list, testimonials_list, workshop_list } from '../../../../Studio-Services/Studio_Services'
import AddTestimonialsImage from './AddTestimonialsImage'
import { Web_Edit } from './Web_Edit'

export default function Businesswebsite() {
    const [loder, setLoder] = useState(false);
    const businessID = localStorage.getItem('business_id');
    const userID = localStorage.getItem('id');
    const [isEdit, setIsEdit] = useState(false);


    const [clsData, setClsData] = useState([]);
    const [servlist, setServlist] = useState([]);

    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(true);

    const [clsImage, setClsImage] = useState(false);
    const [wrkImage, setWrkImage] = useState(false);
    const [serv, setServ] = useState(false);
    const [testImage, setTestImage] = useState(false);

    const [wrkdata, setWrkdata] = useState([]);
    const [tetdata, setTetdata] = useState([]);

    const encryptData = (data) => {
        let secretKey = "warriorsms.com";
        const encrypted = CryptoJS.AES.encrypt(data, secretKey).toString();
        return encrypted;
    };


    const webprehandler = () => {
        const busid = encryptData(businessID);
        const uid = encryptData(userID);
        var dataToSend = {
            key1: busid,
            key2: uid
        };

        var queryString = Object.keys(dataToSend).map(key => key + '=' + encodeURIComponent(dataToSend[key])).join('&');
        window.open( process.env.REACT_APP_BASE_URL+'webpreview?' + queryString);
       
    }

    const clslsitHandler = async () => {
        setLoder(true);
        const data = {
            "pageid": "1",
        }
        const resp = await class_list(data);
        if (resp) {
            setLoder(false);
            const list = resp.data.data;
            setClsData(list);
        } else {
            setClsData([]);
        }
        setLoder(false);
    }

    const servicelistHndler = async (id) => {
        setLoder(true);
        const data = {
            "pageid": "1",
        }
        const resp = await book_appointment(data);
        console.log(resp.data);
        if (resp) {
            setLoder(false);
            const data = resp.data;
            setServlist(data);
        } else {
            setServlist([]);
        }
        setLoder(false);
    }

    const workshopHndler = async () => {
        setLoder(true);
        const data = {
            "pageid": "1",
        }
        const resp = await workshop_list(data);
        if (resp) {
            setLoder(false);
            const list = resp.data.data;
            console.log("----", list);
            setWrkdata(list);
        } else {
            setWrkdata([]);
        }
        setLoder(false);

    }

    const testimonialHndler = async () => {
        setLoder(true);
        const data = {
            "pageid": "1",
        }
        const resp = await testimonials_list(data);
        if (resp) {
            setLoder(false);
            const list = resp.data.data;
            setTetdata(list);
        } else {
            setTetdata([]);
        }
        setLoder(false);
    }

    const tabHnadler = (name) => {

        if (name === "class") {
            clslsitHandler();
            setTestImage(false);
            setWrkImage(false);
            setServ(false);
            setClsImage(!clsImage);
        } else if (name === "services") {
            servicelistHndler();
            setClsImage(false);
            setTestImage(false);
            setWrkImage(false);
            setServ(!serv);

        } else if (name === "workshops") {
            workshopHndler();
            setClsImage(false);
            setTestImage(false);
            setServ(false);
            setWrkImage(!wrkImage);
        } else if (name === "testimonial") {
            testimonialHndler();
            setClsImage(false);
            setServ(false);
            setWrkImage(false);
            setTestImage(!testImage);
        }
    }



    return (
        <>
            {loder && <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div>}
            <div className="MainDashboard">
                <div className="MainLayout">
                    <Row>
                        <Sidebar />
                        <Col>
                            <div className="RightSide">
                                <HeaderDashboard />
                                <section className="addClient innerContent">
                                    <div className="AddClientContent">
                                        <Container>
                                            <div className='col-md-12' style={{ textAlign: 'center' }}><h3>Business Website</h3></div>
                                            <div className='row mt-4'>
                                                <div style={{ display: "flex", justifyContent: 'right' }}>
                                                    <div className='mr-2'>
                                                        <button type='button' className="btn btn-info mybuttoncolor1 mr-1" style={{ color: "#FFFF" }} onClick={() => setIsEdit(!isEdit)}>{!isEdit ? "Edit" : "Back"}</button>
                                                    </div>
                                                    <div>
                                                        <button type='button' className="btn btn-info mybuttoncolor1 mr-1" style={{ color: "#FFFF" }} onClick={() => webprehandler()}>Web-Preview</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-1'>
                                                <hr style={{ marginTop: '0px' }} />
                                            </div>
                                            {!isEdit ?
                                                <>

                                                    {/* {!clsImage && !wrkImage && !serv && !testImage && */}
                                                    <Container className='mt-5'>
                                                        <div className='mainbody'>
                                                            <div className="bgimg">
                                                                <div className="middle">
                                                                    <h1 className=' animated-text'>Congratulations!</h1>
                                                                    <hr />
                                                                    <h3>Your website registration has been successfully completed. Welcome aboard! </h3>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row '>
                                                            <p className='text-center p-4'> Click the button to add additional images for each of your classes, services, workshops, and testimonials. Images of staff will import from their profile picture in Warrior.</p>

                                                            <div className='row mb-2 d-flex'>
                                                                <div className="d-flex" style={{ justifyContent: "center" }}>
                                                                    <h5 className='mr-4' style={{ color: '#02793f' }}>Additional Images</h5>
                                                                    <button type='button' className={clsImage ? "btn btn-info rmbgmy mr-1" : "btn mybuttoncolor1 mr-1"} style={{ color: "#fff" }} onClick={() => tabHnadler('class')}>Classes</button>

                                                                    <button type='button' className={wrkImage ? "btn btn-info rmbgmy mr-1" : "btn mybuttoncolor1 mr-1"} style={{ color: "#fff" }} onClick={() => tabHnadler('workshops')}>Workshops</button>

                                                                    <button type='button' className={serv ? "btn btn-info rmbgmy mr-1" : "btn mybuttoncolor1 mr-1"} style={{ color: "#fff" }} onClick={() => tabHnadler('services')}>Services</button>

                                                                    <button type='button' className={testImage ? "btn btn-info rmbgmy mr-1" : "btn mybuttoncolor1 mr-1"} style={{ color: "#fff" }} onClick={() => tabHnadler('testimonial')}>Testimonial</button>
                                                                </div>
                                                            </div>


                                                        </div>

                                                    </Container>
                                                    {/* } */}


                                                    {clsImage ?
                                                        <Row className='mt-5'>
                                                            <AddClassImage setLoder={setLoder} clsData={clsData} clslsitHandler={clslsitHandler} />
                                                        </Row>
                                                        : serv ?
                                                            <Row className='mt-5'>
                                                                <AddServiceImage setLoder={setLoder} servicelistHndler={servicelistHndler} servlist={servlist} />
                                                            </Row>
                                                            : wrkImage ?
                                                                <Row className='mt-5'>
                                                                    <AddWorkshopImage setLoder={setLoder} dataHandler={workshopHndler} data={wrkdata} />
                                                                </Row>
                                                                : testImage ?
                                                                    <Row className='mt-5'>
                                                                        <AddTestimonialsImage setLoder={setLoder} dataHandler={testimonialHndler} data={tetdata} />
                                                                    </Row>
                                                                    :
                                                                    ""
                                                    }
                                                </>
                                                :
                                                <Row>
                                                    <Web_Edit setLoder={setLoder} setIsEdit={setIsEdit}/>
                                                </Row>
                                            }
                                        </Container>

                                    </div>
                                </section>
                                <Footer />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
