import React, { useEffect } from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { SubHeader } from '../../Components/sub-header/SubHeader'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { Spinner } from 'react-bootstrap'
import { useState } from 'react'
import { API_business_workshop_details, API_my_book_workshop_list, API_workshop_appointment_cancel } from '../../../Static_Services/Staff_Services'
import moment from 'moment'
import { errorAlert, successAlert } from '../../../Static_Services/Alertmsg'
import { Link, useLocation, useNavigate } from 'react-router-dom'

export const ClientMyWorkshop = () => {
    const [loder, setLoder] = useState(false);


    const [workshopDetail, setWorkshopDetail] = useState([])
    const [classData, setClassData] = useState([]);
    const [instrustorData, setInstrustorData] = useState([])
    const [workshopStatus, setWorkshopStatus] = useState();
    const [transactionID, setTransactionID] = useState();
    const [customerDetail, setCustomerDetail] = useState();
    const [view, setView] = useState();
    const navigate = useNavigate();
    const location = useLocation();
    const [currentUrl, setCurrentUrl] = useState(location.pathname)

    const [userInfo, setUserInfo] = useState([]);
    const [businessDetail, setBusinessDetail] = useState([])

    useEffect(() => {
        if (localStorage.getItem('w_loginDetail')) {
            setUserInfo(JSON.parse(localStorage.getItem('w_loginDetail')));
        }
        if (localStorage.getItem('w_BusinessDetail')) {
            setBusinessDetail(JSON.parse(localStorage.getItem('w_BusinessDetail')));
        }
    }, [])

    const getworkshop = async (status) => {
        setLoder(true);
        const detail = { 'pageid': 1, 'workshop_status': status, "business_id": businessDetail.business_id, };
        const resp = await API_my_book_workshop_list(detail);
        if (resp) {
            let response = resp.data;
            if (response.data.length != 0) {
                response.data.map((el) => {
                    // Date Conversion UTC
                    el.start_date_utc_new = moment.unix(el.schedule_date).format('MMM DD YYYY');
                    //time conversion
                    el.from_time_utc_new = moment.unix(el.start).format('hh mm A');
                    el.to_time_utc_new = moment.unix(el.end).format('hh mm A');
                })
            }
            setWorkshopDetail(response.data);
            setLoder(false);
        } else {
            setLoder(false);
            setWorkshopDetail([]);
        }
    }

    const getWorkshopDetail = async (w_id, s_id, t_id, booking_status) => {
        setView(true);
        setLoder(true)
    
        const detail = { "business_id": businessDetail.business_id, "workshop_id": w_id, 'schedule_id': s_id }
        const resp = await API_business_workshop_details(detail);
        if (resp) {
            let response = resp.data;
            // Date Conversion UTC
            response.data.start_date_utc_new = moment.unix(response.data.schedule_date).format('MMM DD YYYY');
            response.data.end_date_utc_new = moment.unix(response.data.end_date_utc).format('MMM DD YYYY');
            // time conversion

            response.data.from_time_new = moment.unix(response.data.start).format('hh mm A');
            response.data.to_time_new = moment.unix(response.data.end).format('hh mm A');
            if (response.data.instructor_details.length != 0) {

                response.data.instructor_details.map((el) => {
                    el.from_time_new = moment.unix(el.from_time_utc).format('hh mm A');
                    el.to_time_new = moment.unix(el.to_time_utc).format('hh mm A');
                })
            }
            setClassData(response.data);
            setInstrustorData(response.data.instructor_details);
            setWorkshopStatus(booking_status);
            console.log("------------t_ids------",t_id);
            setTransactionID(t_id);
            setCustomerDetail(response.data.customer_details);
            setLoder(false);
        } else {
            setLoder(false);
        }
    }

    const showHide = (event) => {
        getworkshop(event.target.value);
    }

    const Cancle = async () => {
        setLoder(true);
        let detail = { 'transaction_id': transactionID };
        const resp = await API_workshop_appointment_cancel(detail);
        if (resp) {
            successAlert(resp.data.message);
            setView(false);
            setLoder(false);
        } else {
            setLoder(false);
        }
    }

    const redirectUrl = (id, url, urlcurrent) => {
        if (urlcurrent) {
            if (id.ageallow) {
                let detail = { 'id': id.pass_id, 'url': currentUrl };
                navigate(url, { state: { Id: detail } });
            } else {
                errorAlert('You are not eligible');
            }
        } else {
            navigate(url, { state: { Id: id } });
        }
    }

    useEffect(() => {
        if (businessDetail && businessDetail.business_id) {
            getworkshop('0');
        }
    }, [businessDetail])
    return (
        <>
            {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
            <InnerHeader />
            <div className="body-grey">
                <div class="studio-details client-home-inner">
                    <div class="container">
                        <DashboardToSectionDetails />
                        <div class="text-right mb-3">
                            <button type="button" class="btn btn-blue-grad px-2 text-uppercase" onClick={() => window.history.back()}>Back </button>
                        </div>
                        {!view &&
                            <div class="studio-details-lower pb-5" >
                                <div class="row">
                                    <div class="col-12">
                                        <div class="white-bg border-radius6 box-shadow1 p-3">
                                            <div class="col-4 std-detail-select">
                                                <select class="form form-control brand-color1 border-radius6"
                                                    onChange={(e) => showHide(e)}
                                                >
                                                    <option value="0"> My Future Workshops </option>
                                                    <option value="1"> My Past Workshops </option>
                                                </select>
                                            </div>
                                            <div class="centered-cover">
                                                <div class="flex-break">
                                                    <div class="box my-classes">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="detail-top-right pt-2">
                                                                    <div class="text-right">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="activity-card-section mt-4 pt-2">
                                                            {workshopDetail.length != 0 &&
                                                                <div class="row">
                                                                    {workshopDetail.map((classlist) => (
                                                                        <div class="col-12 col-md-4 mb-4">
                                                                            <div class="activity-card box-shadow1 border-radius6 white-bg p-2">
                                                                                <div class="row  pb-1">
                                                                                    <div class="col-12 text-right">
                                                                                        {classlist.booking_status == 'Success' &&
                                                                                            <a class="black-color font-w-100  mr-1 mb-1 paratitle">Success</a>}
                                                                                        {classlist.booking_status == 'Cancel' &&
                                                                                            <a class="black-color font-w-100  mr-1 mb-1 paratitle">Cancelled </a>}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-3 col-sm-3 col-md-12 col-lg-3">
                                                                                        <a href="Javascript:void(0);"
                                                                                            onClick={() => getWorkshopDetail(classlist.workshop_id, classlist.id, classlist.transaction_id, classlist.booking_status)}
                                                                                        >

                                                                                            <div class="activity-card-logo">
                                                                                                <img src={businessDetail.logo} class="box-shadow1 new-log-img" />
                                                                                            </div>
                                                                                        </a>
                                                                                    </div>
                                                                                    {/* <!-- end of col-3 --> */}
                                                                                    <div class="col-9 col-sm-9 col-md-12 col-lg-9 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                                                                                        <a href="Javascript:void(0);"
                                                                                            onClick={() => getWorkshopDetail(classlist.workshop_id, classlist.id, classlist.transaction_id, classlist.booking_status)}
                                                                                        >

                                                                                            <div class="row">
                                                                                                <div
                                                                                                    class="col-7 col-md-12 col-lg-7 mt-0 mt-md-4 mt-lg-0 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                                                                                                    <div class="activity-card-details text-left">
                                                                                                        <h5 class="classname-title black-color mb-0">  {classlist.workshop_name} </h5>
                                                                                                        <ul class="d-flex align-items-center py-2">
                                                                                                            <li>
                                                                                                                <h5>Amount :  {classlist.workshop_price}  </h5>
                                                                                                            </li>

                                                                                                        </ul>


                                                                                                    </div>
                                                                                                </div>
                                                                                                {/* <!-- end of col-7 --> */}
                                                                                                <div class="col-5 col-md-12 col-lg-5 pr-1">
                                                                                                    <div class="text-right pt-1">
                                                                                                        <p class="black-color font-w-100 font-14 mr-1 mb-1">
                                                                                                            {classlist.start_date_utc_new}  </p>
                                                                                                        <div class="card-green-box text-center pt-2">
                                                                                                            <p class="white-color mb-0 mt-1">  {classlist.capacity_used}  /
                                                                                                                {classlist.total_capacity}  </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {/* <!-- end of col-5 --> */}
                                                                                            </div>
                                                                                            {/* <!-- end of row --> */}
                                                                                        </a>
                                                                                        <div class="row pt-3">
                                                                                            <p class="black-color font-w-100 paratitle mb-0">Location :
                                                                                            </p>
                                                                                            {classlist.location &&
                                                                                                <h5 class="heading-titlenew ml-2 mb-0 black-color">
                                                                                                    {!classlist.location_url &&
                                                                                                        <a > {classlist.location} </a>}
                                                                                                    {classlist.location_url &&
                                                                                                        <Link to={classlist.location_url} target="_blank"> {classlist.location}</Link>}
                                                                                                </h5>}
                                                                                            {classlist.address &&
                                                                                                <h5 class="heading-titlenew ml-2 mb-0 black-color" >
                                                                                                    <Link to={classlist.address} target="blank">Other</Link>
                                                                                                </h5>}
                                                                                        </div>
                                                                                        <a href="Javascript:void(0);"
                                                                                            onClick={() => getWorkshopDetail(classlist.workshop_id, classlist.id, classlist.transaction_id, classlist.booking_status)}
                                                                                        >

                                                                                            <div class="row pt-2">
                                                                                                <div class="col-6 col-sm-6 col-md-6 col-lg-6 px-0 border-right">
                                                                                                    <div class="text-left">
                                                                                                        <p class="paratitle black-color mb-1"> Start Time </p>
                                                                                                        <h5 class="classname-title black-color mb-0">  {classlist.from_time_utc_new}
                                                                                                        </h5>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end">
                                                                                                    <div class="text-left">
                                                                                                        <p class="paratitle black-color mb-1"> End Time </p>
                                                                                                        <h5 class="classname-title black-color mb-0">  {classlist.to_time_utc_new}
                                                                                                        </h5>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div >
                                                                        </div >
                                                                    ))}
                                                                </div >
                                                            }
                                                            {workshopDetail.length == 0 &&
                                                                <div>
                                                                    <div class="text-center">
                                                                        <p class="font-awesome black-color font-18 d-inline mx-2 "> No Workshops Are scheduled</p>
                                                                    </div>
                                                                </div >
                                                            }
                                                        </div >
                                                    </div >
                                                </div >
                                            </div >
                                        </div >
                                    </div >
                                </div >
                            </div >
                        }
                    </div >
                    {view &&
                        <div >
                            <div class="client-class-inner mb-4">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-12 col-md-4 mb-4">
                                            <p class="black-color font-w-100 text-uppercase font-20"> workshop </p>
                                            <div class="white-bg box-shadow1 border-radius6 p-3 mt-3 text-center fixed-hight">
                                                <div class="row">
                                                    <div class="col-2 mb-3 ">
                                                        <img src={businessDetail.logo} class="new-log-img" />
                                                    </div>
                                                    <div class="col-10 text-left mb-3">
                                                        <p class="black-color font-w-100 text-uppercase font-20 ml-2 "> {classData.workshop_name}  </p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-12 col-md-4 mb-4">
                                            <p class="black-color font-w-100 text-uppercase font-20"> other details </p>
                                            <div class="white-bg box-shadow1 border-radius6 p-3 mt-3 fixed-hight">
                                                <div class="row ">
                                                    <div class="col-6 col-md-6">
                                                        <div class="text-left">
                                                            <p class="font-14 black-color mb-0"> Date </p>
                                                            <h5 class="title black-color mb-0"> {classData.start_date_utc_new} </h5>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-md-6 border-left">
                                                        <div class="text-left">
                                                            <p class="font-14 black-color mb-0"> Capacity </p>
                                                            <h5 class="title black-color mb-0">  {classData.capacity_used}  /  {classData.total_capacity}
                                                            </h5>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="row mt-3">
                                                    <div class="col-6 col-md-6">
                                                        <div class="text-left">
                                                            <p class="font-14 black-color mb-0"> Start time </p>
                                                            <h5 class="title black-color mb-0"> {classData.from_time_new}</h5>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-md-6 border-left">
                                                        <div class="text-left">
                                                            <p class="font-14 black-color mb-0"> End Time</p>
                                                            <h5 class="title black-color mb-0"> {classData.to_time_new}  </h5>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div class="row mt-3">
                                                    <div class="col-6 col-md-6">
                                                        <div class="text-left">
                                                            <p class="font-14 black-color mb-0">Location </p>
                                                            {classData.location &&
                                                                <h5 class="title mb-0 black-color">
                                                                    {!classData.location_url &&
                                                                        <a> ClassData.location </a>}
                                                                    {classData.location_url &&
                                                                        <Link to={classData.location_url} target="_blank"
                                                                            title={classData.location_url}> {classData.location} </Link>}
                                                                </h5>}
                                                            {classData.address &&
                                                                <h5 class="title mb-0 black-color" >
                                                                    <Link to={classData.address} target="blank">Other</Link></h5>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mt-3">
                                                    <div class="col-12 col-md-12">
                                                        <div class="text-left">
                                                            <p class="font-14 black-color mb-0"> Web Link </p>
                                                            {classData.web_link &&
                                                                <h5 class="title black-color mb-0">
                                                                    <a href={classData.web_link} target="_blank">
                                                                        <i class="fa fa-link" aria-hidden="true" title={classData.web_link}></i>
                                                                    </a>
                                                                </h5>}
                                                            {!classData.web_link &&
                                                                <h5 class="title black-color mb-0">N/A </h5>
                                                            }
                                                        </div>
                                                    </div>
                                                </div >
                                            </div >
                                        </div >

                                        <div class="col-12 col-md-4 mb-4">
                                            <p class="black-color font-w-100 text-uppercase font-20"> description </p>
                                            <div class="white-bg box-shadow1 border-radius6 p-3 mt-3 text-left fixed-hight description-box">
                                                <p class="font-14 black-color mb-0"> Description </p>
                                                <p class="brand-color3 font-12 mb-2">{classData.workshop_description} </p>
                                            </div>
                                        </div>
                                    </div >
                                </div >
                            </div >
                            <div class="mb-2">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="white-bg box-shadow1 border-radius6 p-4">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div class="section-heading pt-2 pb-4 text-left">
                                                            <p class="black-color font-w-100 text-uppercase font-20"> staff / instructors </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-6">
                                                        <div class="section-heading pt-2 pb-3 text-right">
                                                        </div>
                                                    </div>
                                                </div>
                                                {instrustorData.length > 0 &&
                                                    <div class="row">
                                                        {instrustorData.map((itemss) => (
                                                            <div class="col-12 col-md-3 mb-4" >
                                                                <div class="activity-card box-shadow1 border-radius6 white-bg p-4">
                                                                    <div class="col-12 mt-0 mt-md-4 mt-lg-0">
                                                                        <div class="activity-card-details text-left">
                                                                            <a href="Javascript:void(0);"
                                                                                onClick={() => redirectUrl(itemss.instructor_id, '/instructor-detail', '')}
                                                                            >
                                                                                <div class="text-center">
                                                                                    <div class="img-height box-shadow1 mx-auto">
                                                                                        <img src={itemss.instructor_profile_img} class="img-fluid" />
                                                                                    </div>
                                                                                    <p class="black-color font-w-100 font-16 mt-3 mb-2"> {itemss.instructor_name}
                                                                                        {itemss.instructor_lastname} </p>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div >
                            </div >
                            <div class="mb-2">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-12">
                                            {customerDetail && customerDetail.length != 0 &&
                                                <div>
                                                    <div class="row py-4">
                                                        <div class="col-8">
                                                            <h5 class="title black-color font-w-500 text-uppercase font-16 mb-0"> User Details
                                                            </h5>
                                                        </div>
                                                    </div>

                                                    <div class="row  mt-3">
                                                        {customerDetail && customerDetail.map((items) => (
                                                            <div class="col-md-4 mb-4" >
                                                                <div class="class-detail-slot-box box-shadow1 border-radius6 p-3 bg-white">
                                                                    <div class="row">
                                                                        <div class="col-3">
                                                                            <div class="profile-img box-shadow1">
                                                                                <img src={items.profile_img} class="img-fluid" />
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-9">
                                                                            <h5 class="brand-color3 heading-titlenew mb-1"> {items.name}
                                                                                {items.lastname}</h5>
                                                                            <ul class="d-none">

                                                                                <li class="flex-fill">
                                                                                    <p class="black-color paratitle font-w-400 font-14"> {items.Age} ,  {items.gender} </p>
                                                                                </li>
                                                                            </ul>
                                                                            <div>
                                                                                <div class="d-flex align-items-center">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {customerDetail.length == 0 &&
                                                        <div class="row" >
                                                            <div class="text-center">
                                                                <p class="font-awesome black-color font-18 d-inline mx-2 "> No User Available</p>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            }

                                        </div>
                                    </div >
                                </div >


                                <div class="container pb-2">
                                    <div class="row">
                                        <div class="col-6 col-lg-6 mt-2 mt-lg-0">
                                            <div class="s-d-appoint-block-right border-radius6 box-shadow1 white-bg">
                                                <div class="px-3 py-3 text-left light-bg border-t-lr-6">
                                                    <h5 class="title black-color font-w-100 text-uppercase font-16 mb-0"> payment details </h5>
                                                </div>

                                                <div class="px-3 pt-4 pb-2 border-bottom">
                                                    <div class="row mb-2">
                                                        <div class="col-6 text-left">
                                                            <p class="black-color font-w-100 font-18"> Workshop Fees </p>
                                                        </div>

                                                        <div class="col-6 text-right">
                                                            <p class="black-color font-w-100 font-18">{businessDetail.currency}
                                                                {classData.workshop_price} </p>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-6 text-left">
                                                            <p class="black-color font-w-100 font-18"> Tax 1 </p>
                                                        </div>
                                                        <div class="col-6 text-right">
                                                            <p class="black-color font-w-100 font-18">  {businessDetail.currency}  {classData.tax1_rate} </p>
                                                        </div>
                                                        <div class="col-6 text-left">
                                                            <p class="black-color font-w-100 font-18"> Tax 2 </p>
                                                        </div>
                                                        <div class="col-6 text-right">
                                                            <p class="black-color font-w-100 font-18">  {businessDetail.currency}  {classData.tax2_rate} </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="px-3 pt-4 pb-3 border-bottom">
                                                    <div class="row mb-2">
                                                        <div class="col-6 text-left">
                                                            {workshopStatus != 'Success' &&
                                                                <p class="title black-color font-w-100 font-18 mb-0"> Amount
                                                                    Payable </p>}
                                                            {workshopStatus == 'Success' &&
                                                                <p class="title black-color font-w-100 font-18 mb-0" > Amount Paid </p>}
                                                        </div>
                                                        <div class="col-6 text-right">
                                                            <p class="title black-color font-w-100 font-18 mb-0">
                                                                {businessDetail.currency}  {classData.workshop_total_price} </p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div >
                                </div >

                                {workshopStatus == 'Success' &&
                                    <div class="container text-left" >
                                        <button type="button" class="btn btn-blue-grad px-4 text-uppercase mb-0 mb-md-5 mt-2"
                                            onClick={() => Cancle()} > Cancel Booking</button >
                                    </div >
                                }
                            </div >


                        </div >
                    }
                </div >
            </div>

            <Innerfooter />

        </>
    )
}
